<template>
    <section class="content">
        <table class="table table-hover" ref="tableactivity">
            <thead>
                <tr>
                    <th>Modul</th>
                    <th>Keterangan</th>
                    <th>User</th>
                    <th>Waktu</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';

export default {
    name: 'UserGroupList',
    data() {
        return {
            errors: [],
            method: '',
            formTitle: 'Tambah Group',
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
    },
    methods: {
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableactivity, {
            "title": "Data Log",
            "roles": this.$route.params.roles,
            "ajax": "/users/logwatch",
            "columns": [
                { "data": "module_name" },
                { "data": "message" },
                { "data": "username" },
                { "data": "created_on" },
            ],
            filterBy: [0],
            "rowCallback": function(row, data) {
                
            },
        });
    }
}
</script>